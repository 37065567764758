<template>
  <div>
    <InputWrapper
      type="position"
      :label="'Position'"
      :values="position"
      @change="onChangePosition"
    />

    <InputWrapper
      type="number"
      :label="'Intensity'"
      :value="intensity"
      :short="true"
      :min="intensityMin"
      :step="intensityStep"
      @change="intensity = $event"
    />

    <InputWrapper
      type="color"
      :label="'Color'"
      :value="color"
      :short="true"
      @change="color = $event"
    />
    <InputWrapper
      type="number"
      :label="'Distance'"
      :min="0"
      :max="Infinity"
      :step="0.1"
      :value="distance"
      @change="distance = $event"
    />
    <InputWrapper
      type="number"
      :label="'Angle'"
      :min="0"
      :max="angleMax"
      :step="0.01"
      :value="angle"
      @change="angle = $event"
    />
    <InputWrapper
      type="slider"
      :label="'Penumbra'"
      :min="0"
      :max="1"
      :step="0.1"
      :value="penumbra"
      @change="penumbra = $event"
    />
    <InputWrapper
      type="number"
      :label="'Decay'"
      :min="0"
      :max="Infinity"
      :step="0.1"
      :value="decay"
      @change="decay = $event"
    />
    <!-- <InputWrapper type="toggle" v-bind:label="'Cast Shadow'" v-bind:value="" v-on:change="" />
    <InputWrapper type="number" v-bind:label="'Shadow Bias'" v-bind:value="" v-on:change="" />
    <InputWrapper type="number" v-bind:label="'Shadow Normal Bias'" v-bind:value="" v-on:change="" />
    <InputWrapper type="number" v-bind:label="'Shadow Radius'" v-bind:value="" v-on:change="" /> -->
  </div>
</template>

<script>
import mixin from '../NodeSettingMixin'

export default {
  name: 'SpotlightBlock',

  mixins: [mixin],

  data () {
    return {
      intensityMin: 0,
      intensityStep: 0.1,
      angleMax: Math.PI / 2
    }
  },

  computed: {

    position () {
      return {
        x: this.node.position.x,
        y: this.node.position.y,
        z: this.node.position.z
      }
    },

    intensity: {
      get () {
        return this.node.intensity
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.intensity = val
        })
      }
    },

    color: {
      get () {
        return '#' + this.node.color.getHexString()
      },
      set (val) {
        this.gui.updateNode(() => {
          val = val.replace('#', '0x')
          this.node.color.setHex(val)
        })
      }
    },

    decay: {
      get () {
        return this.node.decay
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.decay = val
        })
      }
    },

    penumbra: {
      get () {
        return this.node.penumbra
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.penumbra = val
        })
      }
    },

    distance: {
      get () {
        return this.node.distance
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.distance = val
        })
      }
    },

    angle: {
      get () {
        return this.node.angle
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.angle = val
        })
      }
    }

  },

  methods: {

    onChangePosition (values) {
      this.gui.updateNode(() => {
        if (values.x !== undefined) this.node.position.x = values.x
        if (values.y !== undefined) this.node.position.y = values.y
        if (values.z !== undefined) this.node.position.z = values.z
      })
    }
  }
}

</script>
